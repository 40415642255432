
  import { defineComponent } from 'vue';
  import BaseIcon from "@/components/ui/BaseIcon.vue";
  import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
  import Trigger from "@/components/dropdown/Trigger.vue";
  import DropdownOptions from "@/components/dropdown/DropdownOptions.vue";
  import {Roles} from "@/components/types/enums";

  export default defineComponent({
    name: 'ModelEditItem',
    data: () => ({
      dropdownValue: null as any,
      editMode: false,
      fieldValue: null,
      vehicleTypes: [] as any,
    }),
    components: {
      BaseIcon,
      BaseDropdown,
      Trigger,
      DropdownOptions
    },
    props: {
      index: {
        type: Number
      },
      currentModel: {
        type: Object,
        default: () => null
      },
      canEdit: {
        type: Boolean,
        default: () => null
      },
      fieldName: {
        type: String,
        default: () => null
      },
      currentField: {
        type: String,
        default: () => null
      },
      model: {
        type: Object,
        default: () => null
      }
    },
    computed: {
      getOptionsCategoryModel(): any {
        return [
          'Администратор',
          'Администратор по развитию продукта',
          'Менеджер по продажам',
          'Менеджер по развитию',
          'Менеджер по закупкам',
          'Менеджер службы поддержки'
        ]
      },
      getFieldName() {
        return () => this.fieldName ? this.fieldName : ''
      },
      getCurrentChangedField() {
        return (key: any) => {
          if (key === 'role') {
            const roleId = +Roles[this.currentModel.role.key]
            return this.getOptionsCategoryModel[roleId - 1]
          }

          return this.currentModel[key]
        }
      }
    },
    methods: {
      cancelChangesField() {
        this.editMode = false
        this.changeValue(null)
      },
      setOption(option: any) {
        const roleIndex = this.getOptionsCategoryModel.findIndex((roleName: any) => roleName === option);
        this.dropdownValue = option
        this.changeValue(roleIndex + 1)
      },
      changeValue(value: any) {
        this.$emit('changeFieldValue', {
          field: this.currentField,
          value
        })
      },
    }
  })
