import {httpClient} from "@/httpClient";

function deletePermissionForRole(roleId: string | number, permissionsIds: any) {
  return httpClient.delete(`/admin/roles/${roleId}/permissions/${permissionsIds}`)
}

function getPermissions(roleId: string | number) {
  return httpClient.get(`/admin/roles/${roleId}/permissions`).then((response: any) => response.data)
}

function setPermissionForRole(roleId: string | number, permissionsIds: any) {
  return httpClient.post(`/admin/roles/${roleId}/permissions`, permissionsIds)
}

function getAllPermissions() {
  return httpClient.get('/admin/roles/permissions').then((response: any) => response.data)
}

export default {
  deletePermissionForRole,
  getPermissions,
  setPermissionForRole,
  getAllPermissions
}
