
import {defineComponent, ref, computed, onMounted, toRefs} from 'vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import {Roles, UserParameters} from "@/components/types/enums";
import users from "@/services/users";
import DropdownEllipsis from "@/components/admin-panel/DropdownEllipsis.vue";
import AddUserModal from "@/components/modals/AddUserModal.vue";
import EditUserModal from "@/components/modals/EditUserModal.vue";
import Spinner from "@/components/Spinner.vue";
import DropdownOptions from "@/components/dropdown/DropdownOptions.vue";
import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
import Trigger from "@/components/dropdown/Trigger.vue";

export default defineComponent({
  name: 'AdminPanel',
  emits: ['edit-item', 'isInfoLoading'],
  components: {
    BaseCheckbox,
    DropdownEllipsis,
    AddUserModal,
    EditUserModal,
    Spinner,
    BaseDropdown,
    DropdownOptions,
    Trigger
  },
  setup(props, {emit}) {
    const checked: { [x: string]: any } = ref([]);
    const checkedAll = ref(false as boolean);
    const getApplicabilityHeader = computed(() => Object.values(UserParameters));
    const pickedUser = ref(null)
    const adminPanelDropdownRef = ref(null) as any
    const usersDropdownOptions = ref(['Разблокированные', 'Заблокированные']) as any
    const pickedOptionForUserDropdown = ref(usersDropdownOptions.value[0]) as any
    const usersByStatus = ref(null) as any
    const statuses = ref({
      ['Разблокированные']: 'UNBLOCKED',
      ['Заблокированные']: 'BLOCKED'
    }) as any
    const currentStatusUser = ref('UNBLOCKED')
    const showEditUserModal = ref(false)

    //get all users
    const allUsers = ref([])
    const getApplicabilityBody = computed(() => {
      return usersByStatus.value.map((user: any) => {
        return [
          user.company,
          user.email,
          user.role,
          user.status,
          user.username,
          user
          // {
          //   ...user //  нужен, чтобы корректно отображались колонки и для того, чтобы получить данные юзера для модального окна
          // }
        ]
      })
    })
    //get all users

    // checkboxes
    const setCheckedAll = () => {
      checkedAll.value = !checkedAll.value;
      if (checkedAll.value) {
        checked.value = [];
        usersByStatus.value.forEach((user: any) => {
          checked.value.push(user.id)
        });
      } else {
        checked.value = []
      }
    }
    const setChecked = (item: any) => {
      let index = checked.value.findIndex((o: any) => {
        return o === item.id
      })

      if (index !== -1) {
        checked.value.splice(index, 1);
        checkedAll.value = false
      } else {
        checked.value.push(item.id);
        if (usersByStatus.value.length === checked.value.length) {
          checkedAll.value = true
        }
      }
    }
    // checkboxes

    //methods for modal edit user profile
    const openModalEditUserProfile = (user: any) => {
      showEditUserModal.value = true
      pickedUser.value = user

      setTimeout(() => {
        const element = document.getElementById(`modal-edit-user`)!;
        element.style.display = 'flex'
      }, 0)
    }

    const closeModalEditUserProfile = () => {
      showEditUserModal.value = false
      const element = document.getElementById(`modal-edit-user`)!;
      element.style.display = 'none'
    }

    const openModalAddUser = () => {
      const element = document.getElementById(`modal-add-user`)!;
      element.style.display = 'flex'
    }

    const closeModalAddUser = () => {
      const element = document.getElementById(`modal-add-user`)!;
      element.style.display = 'none'
    }
    //methods for modal edit user profile

    const setUsersByStatus = async (status: any) => {
      allUsers.value = []
      allUsers.value = await users.getAllUsers()
      usersByStatus.value = allUsers.value.filter((user: any) => user.status === status)
    }

    const blockUserProfile = async (status: string) => {
      await users.blockedAndUnblockedUser(status, checked.value)
      checked.value = []
      checkedAll.value = false
      allUsers.value = []
      setUsersByStatus(statuses.value[pickedOptionForUserDropdown.value])
    }

    const setOption = (option: any) => {
      allUsers.value = []
      currentStatusUser.value = statuses.value[option]
      setUsersByStatus(currentStatusUser.value)

      pickedOptionForUserDropdown.value = option
      adminPanelDropdownRef.value.close()
    }

    const saveChangesByUser = (payload: any) => {
      const index = usersByStatus.value.findIndex((user: any) => user.id === payload.id)

      if (typeof payload.role === 'number') {
        payload.role = Roles[payload.role]
      }

      usersByStatus.value[index] = payload
    }

    const searchUserByName = (value: string) => {
      usersByStatus.value = allUsers.value.filter((user: any) => {
        return user.username.toLowerCase().includes(value.toLowerCase()) && currentStatusUser.value === user.status
      })
    }

    onMounted(async () => {
      currentStatusUser.value = 'UNBLOCKED'
      await setUsersByStatus(currentStatusUser.value)
    })

    return {
      checked,
      checkedAll,
      getApplicabilityHeader,
      getApplicabilityBody,
      setCheckedAll,
      setChecked,
      blockUserProfile,
      openModalEditUserProfile,
      closeModalEditUserProfile,
      openModalAddUser,
      closeModalAddUser,
      pickedUser,
      allUsers,
      setOption,
      adminPanelDropdownRef,
      usersDropdownOptions,
      pickedOptionForUserDropdown,
      usersByStatus,
      saveChangesByUser,
      showEditUserModal,
      setUsersByStatus,
      searchUserByName
    }
  }
})
