
import { defineComponent } from 'vue';
import Trigger from "@/components/dropdown/Trigger.vue";
import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
import DropdownOptionsForUserRoles from "@/components/admin-panel/DropdownOptionsForUserRoles.vue";
import {Roles} from "@/components/types/enums";

export default defineComponent({
  name: 'GroupFieldsForAddUser',
  components: {
    BaseDropdown,
    Trigger,
    DropdownOptionsForUserRoles
  },
  data: () => ({
  }),
  props: {
    inputValue: {
      type: String
    },
    field: {
      type: String
    }
  },
  computed: {
    getCurrentFieldName(): any {
      switch (this.field) {
        case 'username':
          return `Имя пользователя`
        case 'company':
          return `Компания`
        case 'password':
          return `Пароль`
        case 'role':
          return `Роль`
        case 'email':
          return `E-mail`
        default:
          return this.field
      }
    },
    getRolesName() {
      return [
        "",
        "Администратор",
        "Администратор по развитию продукта",
        "Менеджер по продажам",
        "Менеджер по развитию",
        "Менеджер по закупкам",
        "Менеджер службы поддержки"
      ]
    }
  },
  methods: {
    setOption(option: any) {
      const roles = {
        "Администратор": 1,
        "Администратор по развитию продукта": 2,
        "Менеджер по продажам": 3,
        "Менеджер по развитию": 4,
        "Менеджер по закупкам": 5,
        "Менеджер службы поддержки": 6,
      } as any

      this.changeFieldValue(roles[option])
      const refDropdown = this.$refs.customDropdown as InstanceType<typeof BaseDropdown>;
      refDropdown.close()
    },
    changeFieldValue(value: any) {
      this.$emit('changeValue', this.field, value)
    },
  },
})
