
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import {ConvertDate} from "@/components/converters/date";
import { GetUserName } from "@/services/authentication";
import BaseError from "@/components/ui/BaseError.vue";
import GroupFieldsEditUser from "@/components/admin-panel/GroupFieldsEditUser.vue";
import users from "@/services/users";
import {Roles} from "@/components/types/enums";
import Spinner from "@/components/Spinner.vue";

export default defineComponent({
  inheritAttrs: false,
  name: 'ModelEditModal',
  components: {
    ModalWrapper,
    BaseError,
    GroupFieldsEditUser,
    Spinner
  },
  data: () => ({
    errors: [] as any,
    edit: false,
    currentUser: null as any,
    userData: {
      username: null as any,
      company: null as any,
      email: null as any,
      role: null as any,
      status: null as any,
      passwordFirst: null as any,
      passwordSecond: null as any
    } as any
  }),
  props: {
    user: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    checkDisabledSaveBtn(): any {
      return Object.values(this.userData).some((field: any) => field !== null)
    },
    convertDate() {
      return (date: any) => {
        return ConvertDate(date)
      }
    },
    getUserName() {
      return (id: number) => {
        return GetUserName(id)
      }
    },
    getFieldName() {
      return (key: string) => {
        switch (key) {
          case 'username':
            return 'Имя пользователя'
          case 'company':
            return 'Компания'
          case 'email':
            return 'E-mail'
          case 'role':
            return 'Роль'
          case 'status':
            return 'Статус'
          case 'passwordFirst':
            return 'Пароль'
          case 'passwordSecond':
            return 'Повторите пароль'
          default:
            break;
        }
      }
    },
    canEdit() {
      return (key: string) => {
        switch (key) {
          case 'username':
          case 'company':
          case 'email':
          case 'role':
          case 'passwordFirst':
          case 'passwordSecond':
            return true
            break;
          default:
            return false
            break;
        }
      }

    },
    checkValidValue(): any {
      return (value: any) => !!(value && value.trim().length);
    }
  },
  methods: {
    changeFieldValue(dataField: any) {
      const { value, field } = dataField
      this.userData[field] = value
    },
    async saveChanges() {
      if (this.checkDisabledSaveBtn) {
        if (this.userData.passwordSecond !== null || this.userData.passwordFirst !== null) {
          if (this.userData.passwordSecond !== this.userData.passwordFirst) {
            this.errors = []
            this.errors.push('Пароли не совпадают')
            return;
          } else {
            this.errors = []
          }
        }

        const payload = {
          username: this.userData.username || this.user.username,
          email: this.userData.email || this.user.email,
          role: this.userData.role ? this.userData.role : Number(+this.user.role) ? this.user.role : Roles[this.user.role],
          status: this.userData.status || this.user.status,
          company: this.userData.company || this.user.company,
          id: this.user.id,
          ...(this.userData.passwordSecond && {password: this.userData.passwordSecond }),
        }


        await users.updateUser(payload)
        this.$emit('closeModalEditUser')
        this.$emit('saveChangesByUser', {
          ...this.user,
          ...payload
        })
      }
    }
  },
  async mounted() {
    const response = await users.getUserById(this.user.id)
    response.passwordFirst = ''
    response.passwordSecond = ''
    console.log(response)
    this.currentUser = response
  }
})
