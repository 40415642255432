import {httpClient} from "@/httpClient";

function getAllRoles() {
  return httpClient.get(`/admin/roles`).then((response: any) => response.data)
}

function addRole(payload: {key: string, name: string}) {
  return httpClient.post(`/admin/roles`, payload)
}

function deleteRoleById(roleId: string | number) {
  return httpClient.delete(`/admin/roles/${roleId}`)
}

function getRoleById(roleId: string | number) {
  return httpClient.get(`/admin/roles/${roleId}`).then((response: any) => response.data)
}

function updateRole(roleId: string | number, payload: {key: string, name: string}) {
  return httpClient.get(`/admin/roles/${roleId}`)
}

function searchRole(descName: string) {
  return httpClient.get(`/admin/roles?descName=${descName}`)
}

export default {
  getAllRoles,
  addRole,
  deleteRoleById,
  getRoleById,
  updateRole,
  searchRole,
}
