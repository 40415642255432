
import {defineComponent, ref, computed, onMounted, toRefs} from 'vue';
import permissions from "@/services/permissions";
import Spinner from "@/components/Spinner.vue";
import DropdownEllipsis from "@/components/admin-panel/DropdownEllipsis.vue";
import roles from "@/services/roles";
import ToggleButton from "@/components/admin-panel/ToggleButton.vue";
import BaseToggleButtons from '@/components/ui/BaseToggleButtons.vue';

export default defineComponent({
  name: 'Permissions',
  components: {
    Spinner,
    DropdownEllipsis,
    ToggleButton,
    BaseToggleButtons
  },
  setup(props, {emit}) {
    const allRoles = ref(null) as any
    const allPermissionsAdmin = ref(null) as any

    const changeStatePermission = async (state: boolean, ids: any) => {
      if (!state) {
        await permissions.deletePermissionForRole(ids.roleId, ids.permissionId)
      } else {
        await permissions.setPermissionForRole(ids.roleId, [+ids.permissionId])
      }
    }

    onMounted(async () => {
      allRoles.value = await roles.getAllRoles()
      allPermissionsAdmin.value = await permissions.getAllPermissions()
    })

    return {
      allRoles,
      allPermissionsAdmin,
      changeStatePermission
    }
  }
})
