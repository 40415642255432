
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent, reactive } from 'vue';
import GroupFieldsForAddUser from "@/components/admin-panel/GroupFieldsForAddUser.vue";
import users from "@/services/users";

export default defineComponent({
  name: 'AddUser',
  components: {
    ModalWrapper,
    GroupFieldsForAddUser
  },
  setup(props, { emit }) {
    const userData = reactive({
      username: null,
      company: null,
      password: null,
      role: null,
      email: null
    } as any)

    const createNewUser = async () => {
      await users.createUser({
        ...userData,
        username: userData.username.toLowerCase()
      })
      emit('closeModal')
      emit('getNewListUsers')
    }

    const setValue = (field: string, value: string) => {
      userData[field] = value
    }

    return {
      userData,
      createNewUser,
      setValue
    }
  }
})
