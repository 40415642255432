
import AdminPanel from "@/components/admin-panel/AdminPanel.vue";
import Permissions from "@/components/admin-panel/Permissions.vue";
import {defineComponent, ref, computed, onMounted, toRefs} from 'vue';

export default defineComponent({
  name: 'AdminPanelWrapper',
  components: {
    AdminPanel,
    Permissions
  },
  setup() {
    const menu = ref([
      {key: 'admin-panel', value: 'Пользователи'},
      {key: 'permissions', value: 'Доступы'},
    ])

    const activeMenu = ref(menu.value[0].key)

    const setActiveMenu = (option: any) => {
      activeMenu.value = option.key
    }

    return {
      menu,
      activeMenu,
      setActiveMenu
    }
  }
})
